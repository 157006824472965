import { useState, useEffect, forwardRef } from "react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  Table,
  Flex,
  useToast,
  VStack,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton,
  Select,
  Button,
  Box,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getKPIData, getSites } from "../api";
import ExportCSV from "../components/ExportCSV";

const DEFAULT_START_DATE = new Date();
const DEFAULT_END_DATE = new Date();
DEFAULT_START_DATE.setDate(DEFAULT_START_DATE.getDate() - 30);
/**
 * Key performance indicators
 */
const KPIStats = () => {
  const [KPIData, setKPIData] = useState([]);
  const [sites, setSites] = useState([]);
  const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState(DEFAULT_END_DATE);
  const [selectedSite, setSelectedSite] = useState({ sap_website_id: 8042 });
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: null });
  const toast = useToast();

  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };
    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button size="sm" onClick={onClick} ref={ref} leftIcon={<CalendarIcon />}>
      {title}: {value}
    </Button>
  ));

  useEffect(() => {
    const loadKPIData = async () => {
      try {
        setLoading(true);
        const KPIResponse = await getKPIData(
          selectedSite.sap_website_id,
          startDate.toISOString(),
          endDate.toISOString()
        );
        setKPIData(KPIResponse);
      } catch (e) {
        console.log(e);
        errorToast(e);
        setKPIData([]);
      } finally {
        setLoading(false);
      }
    };
    loadKPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedSite]);

  useEffect(() => {
    const KPIDataSorted = [...KPIData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setKPIData(KPIDataSorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig]);

  const errorToast = (error) =>
    toast({
      title: "Failed to load stats",
      description: error?.data?.message ?? "Something went wrong",
      status: "error",
      duration: 3500,
      isClosable: true,
    });

  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <VStack width="90vw" margin="0 auto">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Flex alignItems="center" justifyContent="flex-start">
          <Select
            variant="filled"
            placeholder={sites?.length ? "Select site" : "No sites found"}
            defaultValue={selectedSite?.sap_website_id}
            value={selectedSite?.sap_website_id}
            onChange={(e) =>
              setSelectedSite({ sap_website_id: e?.target?.value })
            }
            size="sm"
            width="150px"
            borderRadius="5px"
            mr="20px"
            my="10px"
          >
            {sites?.length
              ? sites?.map((site) => (
                  <option
                    value={site?.sap_website_id}
                    key={site?.sap_website_id}
                  >
                    Site:&nbsp;{site?.sap_website_id}
                  </option>
                ))
              : null}
          </Select>
          <Box my="10px" mr="20px">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="PP"
              title={"Start Date"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
            />
          </Box>
          <Box my="10px" mr="20px">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="PP"
              title={"End Date"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
            />
          </Box>
        </Flex>
        <Flex justifyContent="flex-end">
          <ExportCSV
            data={KPIData}
            filename={`KPI-${selectedSite.sap_website_id}`}
          />
        </Flex>
      </Flex>
      <Table variant="striped" size="md" my={4}>
        <Thead>
          <Tr>
            <Th>Employee ID</Th>
            <Th>Employee Name</Th>
            <Th>Role</Th>
            <Th>Site ID</Th>
            <Th
              _hover={{ cursor: "pointer" }}
              _active={{ transform: "translateY(1px)" }}
              onClick={() => requestSort("best_time")}
            >
              Best Time (m)
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              _active={{ transform: "translateY(1px)" }}
              onClick={() => requestSort("average_time")}
            >
              Average Time (m)
            </Th>
            <Th
              _hover={{ cursor: "pointer" }}
              _active={{ transform: "translateY(1px)" }}
              onClick={() => requestSort("orders_handled")}
            >
              Orders Handled
            </Th>
          </Tr>
        </Thead>
        {loading ? (
          <Tbody>
            {[1, 2, 3, 4, 5].map((i) => (
              <Tr key={i}>
                {[1, 2, 3, 4, 5, 6, 7].map((j) => (
                  <Td key={j}>
                    <Skeleton height="25px" />
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        ) : (
          <Tbody>
            {KPIData?.map((employee, idx) => (
              <Tr key={idx}>
                <Td>{employee?.employee_id ?? "--"}</Td>
                <Td>{employee?.name ?? "--"}</Td>
                <Td>{employee?.role ?? "--"}</Td>
                <Td>{employee?.sap_site_id ?? "--"}</Td>
                <Td>{employee?.best_time ?? "--"}</Td>
                <Td>{employee?.average_time ?? "--"}</Td>
                <Td>{employee?.orders_handled ?? "--"}</Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
    </VStack>
  );
};

export default KPIStats;
