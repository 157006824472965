import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableCaption,
  Flex,
  useToast,
  Badge,
  Button,
  Select,
  Box
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from "@chakra-ui/icons";

import { exportToExcel } from "../utils/exportToExcel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOrderTimelineReport, getSites } from "../api";
import { OrderTimelineReportTableHead } from "../components/TableHeads";
import OrderTimelineReportTable from "../containers/OrderTimelineReportTable";
import dayjs from "dayjs";
import LineChart from "../components/LineChart";
import Pagination from "../components/Pagination";
/**
 * Order time line report
 */
const OrderTimelineReport = () => {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const selectID = params.get("name");
  const defaultSiteID = params.get("id");
  const [timeSlotArray, setTimeSlotArray] = useState([]);
  const [timeSlotSelected, setTimeSlotSelected] = useState("all");
  const [emptyLoading, setemptyLoading] = useState(false);
  const [chartData, setChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {}
  });
  const [fromDate, setFromDate] = useState(
    new Date(dayjs().subtract(7, "days").format())
  );
  const [reportData, setReportData] = useState([]);
  const [reportFinalData, setReportFinalData] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [selectedSite, setSelectedSite] = useState(
    defaultSiteID ? 8042 : "all"
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowToShow, setRowToShow] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // set chart data
  useEffect(() => {
    const xChartData = [];
    const y1ChartData = [];
    const y2ChartData = [];
    const y3ChartData = [];
    const y4ChartData = [];
    const y5ChartData = [];
    reportFinalData?.map(report => {
      xChartData?.push(report?.timeslot);
      y1ChartData?.push(report?.express_orders);
      y2ChartData?.push(report?.online_payment_orders);
      y3ChartData?.push(report?.pay_on_delivery_orders);
      y4ChartData?.push(report?.scheduled_orders);
      y5ChartData?.push(report?.total_orders);
    });
    setChartData({
      xData: xChartData,
      yData: [
        {
          label: "Express Orders",
          data: y1ChartData,
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        },
        {
          label: "Online Payment Orders",
          data: y2ChartData,
          backgroundColor: ["rgba(54, 162, 235, 0.2)"],
          borderColor: ["rgba(54, 162, 235, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        },
        {
          label: "Pay On Delivery Orders",
          data: y3ChartData,
          backgroundColor: ["rgba(255, 206, 86, 0.2)"],
          borderColor: ["rgba(255, 206, 86, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        },
        {
          label: "Scheduled Orders",
          data: y4ChartData,
          backgroundColor: ["rgba(75, 192, 192, 0.2)"],
          borderColor: ["rgba(75, 192, 192, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        },
        {
          label: "Total Orders",
          data: y5ChartData,
          backgroundColor: ["rgba(153, 102, 255, 0.2)"],
          borderColor: ["rgba(153, 102, 255, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        }
      ],
      optionScale: {
        x: {
          position: "bottom"
        }
      }
    });
  }, [reportFinalData]);
  useEffect(() => {
    if (timeSlotSelected === "all") {
      setReportFinalData(reportData);
    } else {
      let dummyData = reportData?.filter(item =>
        item?.timeslot?.includes(timeSlotSelected)
      );

      setReportFinalData(dummyData);
    }
  }, [reportData, timeSlotSelected]);
  const [sites, setSites] = useState([]);

  //load site data
  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownloadClick = async () => {
    try {
      exportToExcel(
        "ordertimeline",
        reportFinalData,
        `ORDER-TIMELINE-${dayjs(fromDate).format("DD-MM-YYYY")}-${dayjs(
          toDate
        ).format("DD-MM-YYYY")}:${selectedSite}`,
        "ORDER-TIMELINE-REPORT"
      );
    } catch (e) {
      console.log(e?.data?.message);
      errorToast("error", "Error", "To date should be greater than from date");
    }
  };

  useEffect(() => {
    getReportData();
  }, [fromDate, toDate, selectedSite]);

  const handleToDate = date => {
    if (dayjs(date)?.diff(dayjs(fromDate), "days") < 0) {
      errorToast("error", "Error", "To date should be greater than from date");
      setFromDate(date);
    }
    if (dayjs(date)?.diff(dayjs(fromDate), "days") >= 15) {
      errorToast(
        "info",
        "Info",
        "Maximum range that can be selected is 15 days"
      );
      setFromDate(new Date(dayjs(date).subtract(14, "days").format()));
    }
    setToDate(date);
  };

  const handleFromDate = date => {
    if (dayjs(toDate)?.diff(dayjs(date), "days") >= 15) {
      setToDate(new Date(dayjs(date).add(14, "days").format()));
      errorToast(
        "info",
        "Info",
        "Maximum range that can be selected is 15 days"
      );
    }
    setFromDate(date);
  };
  // get all data between two dates

  const getReportData = async () => {
    try {
      let start_date = dayjs(fromDate)?.format("YYYY-MM-DD");
      let end_date = dayjs(toDate)?.format("YYYY-MM-DD");
      setemptyLoading(true);
      const response = await getOrderTimelineReport(
        selectedSite,
        start_date,
        end_date
      );
      setTimeSlotArray([
        ...new Set(response?.data?.map(i => i?.timeslot?.split(" ")[1]))
      ]);
      setReportData(response?.data);
    } catch (e) {
      errorToast("error", "Error", e?.data?.message);
    } finally {
      setemptyLoading(false);
    }
  };

  //pagination refresh
  useEffect(() => {
    setTotalPages(Math.ceil(reportData?.length / rowToShow) ?? 0);
    setCurrentPage(1);
  }, [rowToShow, reportData]);

  // customDatePicker 📅
  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      disabled={emptyLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));

  //toast
  const errorToast = (type, title, msg) =>
    toast({
      title: title ?? "Something went wrong",
      description: msg ?? "Something went wrong",
      status: type,
      duration: 2500,
      isClosable: true
    });

  return (
    <Flex direction="column">
      <Box width="185px" mr="20px" my="10px">
        <Badge variant="subtle" fontSize="16px" colorScheme={"twitter"}>
          Order Timeline Report
        </Badge>
      </Box>
      <Flex direction="row" justifyContent="space-between">
        <Select
          variant="filled"
          // placeholder={sites?.length ? "Select site" : "No sites found"}
          value={selectedSite}
          onChange={e => {
            setSelectedSite(e.target.value);
          }}
          size="sm"
          width="150px"
          borderRadius="5px"
          mr="20px"
          my="10px"
        >
          <option value="all">All sites</option>
          {sites?.length
            ? sites?.map(site => {
                return (
                  <option
                    value={
                      selectID === "website_id"
                        ? site.sap_website_id
                        : site.default_store_id
                    }
                    key={
                      selectID === "website_id"
                        ? site.sap_website_id
                        : site.default_store_id
                    }
                  >
                    Site:&nbsp;{site?.sap_website_id}
                  </option>
                );
              })
            : null}
        </Select>
        <Flex my="10px" mr="20px">
          <Box mx={"20px"}>
            <DatePicker
              selected={fromDate}
              maxDate={new Date(toDate)}
              onChange={handleFromDate}
              dateFormat="PP"
              title={"Select From"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
          <Box mx={"20px"}>
            <DatePicker
              selected={toDate}
              maxDate={new Date()}
              onChange={handleToDate}
              dateFormat="PP"
              title={"Select To"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
        </Flex>
        <Button
          size="sm"
          isLoading={emptyLoading}
          disabled={emptyLoading || !selectedSite || !sites?.length}
          rightIcon={<DownloadIcon />}
          onClick={onDownloadClick}
          my="10px"
        >
          Download
        </Button>
      </Flex>
      <Flex direction="row-reverse">
        <Select
          variant="filled"
          defaultValue={timeSlotSelected}
          value={timeSlotSelected}
          onChange={e => {
            setTimeSlotSelected(e.target.value);
          }}
          size="sm"
          width="150px"
          borderRadius="5px"
          mr="20px"
          my="10px"
        >
          <option value="all">All Timeslots</option>
          {timeSlotArray?.map(slot => {
            return <option value={slot}>{slot}</option>;
          })}
        </Select>
      </Flex>
      <Flex mb="10">
        <LineChart height={"80px"} chartData={chartData} />
      </Flex>

      <Flex>
        <Table variant="simple" size="md">
          <TableCaption placement={"top"} textAlign="left">
            Order Timeline Report
          </TableCaption>
          <OrderTimelineReportTableHead />
          <OrderTimelineReportTable
            data={reportFinalData?.slice(
              (currentPage - 1) * rowToShow,
              currentPage * rowToShow
            )}
          />
        </Table>
      </Flex>
      <Pagination
        page={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        rowToShow={rowToShow}
        setRowToShow={setRowToShow}
      />
    </Flex>
  );
};
export default OrderTimelineReport;
