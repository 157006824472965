import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { AuthContext } from "../context/AuthContext";
import Header from "../containers/Header";
import Home from "../screens/Home";
import Login from "../screens/Login";
import SalesReport from "../screens/SalesReport";
import CouponReport from "../screens/CouponReport";
import LocationReport from "../screens/LocationReport";
import AgentSummaryReport_v1 from "../screens/AgentSummaryReport_v1";
import TotalReport from "../screens/TotalReport";
import KPIStats from "../screens/KPIStats";
import NOBReport from "../screens/NOBReport";
import OrderTimelineReport from "../screens/OrderTimelineReport";
import CategoryPerformanceReport from "../screens/CategoryPerformanceReport";
import TopAndSlowMovingProducts from "../screens/TopAndSlowMovingProducts";
import OrdersAndCancellationReport from "../screens/OrdersAndCancellationReport";
import StockMovementReport from "../screens/StockMovementReport";
import HubLevelReport from "../screens/HubLevelReport";

const RootRoute = () => {
  const { isLoggedIn, checkAuthState } = useContext(AuthContext);

  useEffect(() => {
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Header />
      <Box px="25px" pt="80px" pb="40px">
        {isLoggedIn ? (
          <Switch>
            <Route exact path="/sales-report" component={SalesReport} />
            <Route exact path="/eod-report" component={AgentSummaryReport_v1} />
            <Route exact path="/location-report" component={LocationReport} />

            <Route exact path="/nob-report" component={NOBReport} />
            <Route exact path="/promotion-report" component={CouponReport} />
            <Route
              exact
              path="/order-timeline-report"
              component={OrderTimelineReport}
            />
            <Route
              exact
              path="/stock-movement-report"
              component={StockMovementReport}
            />
            <Route
              exact
              path="/total_reports/private"
              component={TotalReport}
            />
            <Route exact path="/kpi-stats" component={KPIStats} />
            <Route
              exact
              path="/category-performance"
              component={CategoryPerformanceReport}
            />
            <Route
              exact
              path="/top-slow-moving-products"
              component={TopAndSlowMovingProducts}
            />
            <Route
              exact
              path="/orders-cancellation"
              component={OrdersAndCancellationReport}
            />
            <Route exact path="/hub-level" component={HubLevelReport} />
            <Route exact path="/" component={Home} />
          </Switch>
        ) : (
          <Switch>
            <Route component={Login} />
          </Switch>
        )}
      </Box>
    </Router>
  );
};

export default RootRoute;
