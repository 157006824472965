import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  Flex,
  useToast,
  Badge,
  Button,
  TableCaption,
  Select,
  Box,
  Input,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon, SmallCloseIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getStockMovementReport, getSites } from "../api";
import { exportToExcel } from "../utils/exportToExcel";
import { GeneralTableHead } from "../components/TableHeads";
// import StockMovementReportTableWithHead from "../containers/StockMovementReportTableWithHead";
import StockMovementReportTable from "../containers/StockMovementReportTable";

import Pagination from "../components/Pagination";
import LineChart from "../components/LineChart";
import dayjs from "dayjs";
import sort from "../utils/sort";

/**
 * Stock movement report
 */
const StockMovementReport = () => {
  const initRef = useRef();
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const selectID = params.get("name");
  const defaultSiteID = params.get("id");
  const [sortKey, setSortKey] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [sites, setSites] = useState([]);
  const [emptyLoading, setemptyLoading] = useState(false);
  const [chartData, setChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {}
  });
  const [fromDate, setFromDate] = useState(
    new Date(dayjs().subtract(14, "days").format())
  );
  const [isListOpen, setIsListOpen] = useState(false);
  const [filterReport, setFilterReport] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportSearchData, setReportSearchData] = useState([]);
  const [checkedArray, setCheckedArray] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [selectedSite, setSelectedSite] = useState(
    defaultSiteID ? defaultSiteID : "all"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowToShow, setRowToShow] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const openList = () => setIsListOpen(true);
  const closeList = () => setIsListOpen(false);

  // get all data between two dates
  const handleToDate = date => {
    if (dayjs(date)?.diff(dayjs(fromDate), "days") < 0) {
      errorToast("error", "Error", "To date should be greater than from date");
      setFromDate(date);
    }
    if (dayjs(date)?.diff(dayjs(fromDate), "months") > 1) {
      errorToast(
        "info",
        "Info",
        "Maximum range that can be selected is a month"
      );
      setFromDate(new Date(dayjs(date).subtract(1, "months").format()));
    }
    setToDate(date);
  };

  const handleFromDate = date => {
    if (dayjs(toDate)?.diff(dayjs(date), "months") > 1) {
      setToDate(new Date(dayjs(date).add(1, "months").format()));
      errorToast(
        "info",
        "Info",
        "Maximum range that can be selected is a month"
      );
    }
    setFromDate(date);
  };

  const getReportData = async (
    store_id = selectedSite !== "all" ? parseInt(selectedSite) : "all",
    start_date = dayjs(fromDate)?.format("YYYY-MM-DD"),
    end_date = dayjs(toDate)?.format("YYYY-MM-DD")
  ) => {
    try {
      setemptyLoading(true);
      const response = await getStockMovementReport(
        store_id,
        start_date,
        end_date
      );
      setReportData(response);
      setReportSearchData(response);
    } catch (e) {
      errorToast("error", "Error", e?.data?.message);
    } finally {
      setemptyLoading(false);
    }
  };

  const onDownloadClick = async () => {
    try {
      exportToExcel(
        "stockmovementreport",
        reportData,
        `Stock Movement Report-${dayjs(fromDate).format("DD-MM-YYYY")}-${dayjs(
          toDate
        ).format("DD-MM-YYYY")}:${selectedSite}`,
        "Stock Movement Report"
      );
    } catch (e) {
      console.log(e?.data?.message);
      errorToast("error", "Error", "To date should be greater than from date");
    } finally {
    }
  };

  //toast
  const errorToast = (type, title, msg) =>
    toast({
      title: title ?? "Something went wrong",
      description: msg ?? "Something went wrong",
      status: type,
      duration: 2500,
      isClosable: true
    });

  // sort
  const handleSort = header => {
    let sorted_array = sort(header.id, header.type, filterReport);
    setFilterReport(sorted_array);
    setSortKey(header.id);
  };

  const filterOnProductSelect = () => {
    if (checkedArray?.length > 0) {
      setFilterReport(checkedArray);
    } else {
      setFilterReport(reportData);
    }
  };

  const addToFilter = item => {
    if (
      checkedArray?.filter(
        checkedItem => checkedItem?.product_id === item?.product_id
      )?.length === 0
    ) {
      console.log(reportSearchData);
      setReportSearchData(
        reportSearchData?.filter(
          reportItem => reportItem?.product_id !== item?.product_id
        )
      );
      setCheckedArray([...checkedArray, item]);
    }
  };

  const removeFromFilter = item => {
    if (
      filterReport?.filter(
        filterItem => filterItem?.product_id === item?.product_id
      )?.length > 0
    ) {
      setReportSearchData([...reportSearchData, item]);
      setCheckedArray(
        checkedArray?.filter(
          checkedItem => checkedItem?.product_id !== item?.product_id
        )
      );
    }
  };

  //load site data
  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //loadingf report
  useEffect(() => {
    getReportData();
  }, [fromDate, toDate, selectedSite]);

  //pagination refresh
  useEffect(() => {
    setTotalPages(Math.ceil(filterReport?.length / rowToShow) ?? 0);
    setCurrentPage(1);
  }, [rowToShow, filterReport]);

  useEffect(() => {
    filterOnProductSelect();
  }, [checkedArray, reportData]);

  // set chart data
  useEffect(() => {
    const xChartData = [];
    const y1ChartData = [];
    const y2ChartData = [];
    filterReport?.map(item => {
      xChartData?.push(item?.product_id);
      y1ChartData?.push(item?.sold_qty);
      y2ChartData?.push(item?.stock);
    });
    setChartData({
      xData: xChartData.slice(0, 50),
      yData: [
        {
          label: "Quantity Sold",
          data: y1ChartData.slice(0, 50),
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
          yAxisID: "y"
        },
        {
          label: "Stock",
          data: y2ChartData.slice(0, 50),
          backgroundColor: ["rgba(75, 192, 192, 0.2)"],
          borderColor: ["rgba(75, 192, 192, 1)"],
          borderWidth: 1,
          yAxisID: "y1"
        }
      ],
      optionScale: {
        y: {
          position: "left",
          title: {
            display: true,
            text: "Quantity Sold",
            font: {
              size: 15
            }
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          }
        },
        y1: {
          position: "right",
          title: {
            display: true,
            text: "Stock",
            font: {
              size: 15
            }
          },
          grid: {
            drawOnChartArea: false // only want the grid lines for one axis to show up
          }
        },
        x: {
          position: "bottom"
        }
      }
    });
  }, [filterReport]);

  //Search key updating
  useEffect(() => {
    if (searchKey !== "") openList();
    else closeList();
  }, [searchKey]);

  // customDatePicker 📅
  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      disabled={emptyLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));

  let tableHeaders = [
    {
      id: "name",
      name: "Product (Product Id)",
      isSortable: true,
      type: "string"
    },
    { id: "sku", name: "SKU", isSortable: false, type: "string" },
    {
      id: "sold_qty",
      name: "QUANTITY SOLD",
      isSortable: true,
      type: "number"
    },
    {
      id: "stock",
      name: "TOTAL STOCK",
      isSortable: true,
      type: "number"
    },
    {
      id: "rol",
      name: "REORDER LIMIT",
      isSortable: true,
      type: "number"
    }
  ];

  return (
    <Flex direction="column">
      <Box width="185px" mr="20px" my="10px">
        <Badge variant="subtle" fontSize="16px" colorScheme={"twitter"}>
          Stock Movement Report
        </Badge>
      </Box>
      <Flex direction="row" justifyContent="space-between">
        <Select
          variant="filled"
          // placeholder={sites?.length ? "Select site" : "No sites found"}
          value={selectedSite}
          onChange={e => {
            setSelectedSite(e.target.value);
          }}
          size="sm"
          width="150px"
          borderRadius="5px"
          mr="20px"
          my="10px"
        >
          <option value="all">All sites</option>
          {sites?.length
            ? sites?.map(site =>
                site?.stores?.map(storeItem => (
                  <option
                    value={storeItem?.store_id}
                    key={`${site?.sap_website_id}-${storeItem?.store_name}`}
                  >
                    {`Site: ${site?.sap_website_id} - ${storeItem?.store_name}`}
                  </option>
                ))
              )
            : null}
        </Select>
        <Flex my="10px" mr="20px">
          <Box mx={"20px"}>
            <DatePicker
              selected={fromDate}
              maxDate={new Date(toDate)}
              onChange={handleFromDate}
              dateFormat="PP"
              title={"Select From"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
          <Box mx={"20px"}>
            <DatePicker
              selected={toDate}
              maxDate={new Date()}
              onChange={handleToDate}
              dateFormat="PP"
              title={"Select To"}
              customInput={<CustomDatePicker />}
              todayButton="Select today"
              showPopperArrow={false}
              popperPlacement="bottom"
              disabled={emptyLoading}
            />
          </Box>
        </Flex>
        <Button
          size="sm"
          isLoading={emptyLoading}
          disabled={emptyLoading || !selectedSite || !sites?.length}
          rightIcon={<DownloadIcon />}
          onClick={onDownloadClick}
          my="10px"
        >
          Download
        </Button>
      </Flex>
      <Flex mb="10">
        <LineChart height={"90px"} chartData={chartData} />
      </Flex>
      <Flex>
        <Table variant="simple" size="md">
          <TableCaption placement={"top"} textAlign="left">
            <Flex justifyContent="space-between">
              <Text>Stock Movement Report</Text>
              <Flex>
                <Popover
                  isOpen={isListOpen}
                  onClose={closeList}
                  placement="bottom"
                  initialFocusRef={initRef}
                >
                  <PopoverTrigger>
                    <Input
                      ref={initRef}
                      value={searchKey}
                      width="400px"
                      size="sm"
                      placeholder="Filter product name"
                      onChange={e => setSearchKey(e.target.value)}
                    />
                  </PopoverTrigger>
                  <PopoverContent width="400px">
                    {reportSearchData
                      ?.filter(
                        filterItem =>
                          filterItem?.name
                            ?.toLowerCase()
                            ?.includes(searchKey?.toLowerCase()) ||
                          filterItem?.product_id
                            ?.toString()
                            ?.includes(searchKey?.toString())
                      )
                      ?.map((item, key) => {
                        return (
                          <Text
                            onClick={() => addToFilter(item)}
                            key={key + "-" + item?.product_id}
                            p="2"
                            cursor="pointer"
                            _hover={{
                              background: "lightgrey",
                              color: "teal.500"
                            }}
                          >
                            {item?.name + " (" + item?.product_id + ")"}
                          </Text>
                        );
                      })}

                    {reportSearchData?.filter(
                      filterItem =>
                        filterItem?.name
                          ?.toLowerCase()
                          ?.includes(searchKey?.toLowerCase()) ||
                        filterItem?.product_id
                          ?.toString()
                          ?.includes(searchKey?.toString())
                    )?.length === 0 && <Text p="2">No products found</Text>}
                  </PopoverContent>
                </Popover>
              </Flex>
            </Flex>
            {checkedArray?.length > 0 && (
              <Flex wrap="wrap">
                {checkedArray?.map((item, key) => {
                  return (
                    <Box
                      key={key + "-" + item?.product_id}
                      size="sm"
                      border="1px"
                      borderColor="gray.200"
                      backgroundColor="gray.200"
                      borderRadius="10px"
                      m="1"
                      p="1"
                    >
                      {item?.name}
                      <SmallCloseIcon
                        ml="1"
                        cursor="pointer"
                        onClick={() => removeFromFilter(item)}
                      />
                    </Box>
                  );
                })}
                <Box
                  size="sm"
                  border="1px"
                  borderColor="gray.200"
                  backgroundColor="gray.200"
                  borderRadius="10px"
                  m="1"
                  p="1"
                >
                  Remove all
                  <SmallCloseIcon
                    ml="1"
                    cursor="pointer"
                    onClick={() => {
                      setReportSearchData(reportData);
                      setCheckedArray([]);
                      setSearchKey("");
                    }}
                  />
                </Box>
              </Flex>
            )}
          </TableCaption>
          <GeneralTableHead
            headers={tableHeaders}
            sortKey={sortKey}
            sort={handleSort}
          />
          <StockMovementReportTable
            data={filterReport?.slice(
              (currentPage - 1) * rowToShow,
              currentPage * rowToShow
            )}
          />
        </Table>
      </Flex>
      {filterReport?.length > 0 && (
        <Pagination
          page={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          rowToShow={rowToShow}
          setRowToShow={setRowToShow}
        />
      )}
    </Flex>
  );
};

export default StockMovementReport;
