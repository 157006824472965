import {
  Badge,
  Flex,
  Button,
  Box,
  useToast,
  useBreakpointValue,
  Select,
  Input
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from "@chakra-ui/icons";
import { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);
/**
 * Page header that Contains filters and download functionality.
 */
const ReportHeader = ({
  reportName,
  onDownloadClick,
  isLoading,
  colorScheme,
  sites,
  defaultSiteID,
  updateTable,
  selectID = "website_id",
  searchFilter,
  isSearchFilter = true,
  isMultipleTables = false,
  tables = [],
  selectedTable,
  defaultTableId
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSite, setSelectedSite] = useState(defaultSiteID);
  const [selectedTableId, setSelectedTableId] = useState(defaultTableId);
  const toast = useToast();
  // load default tableId
  useEffect(() => {
    setSelectedTableId(defaultTableId);
  }, [defaultTableId]);

  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      disabled={isLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));

  const updateData = (id, data) => {
    let day = dayjs(selectedDate),
      site = selectedSite;
    if (id === "site") {
      setSelectedSite(data);
      site = data;
    } else if (id === "date") {
      setSelectedDate(data);
      day = dayjs(data);
    }
    if (selectID === "store_id") {
      sites.forEach(item => {
        if (item.website_id == site) {
          site = {
            website_id: item.sap_website_id,
            store_id: item.default_store_id
          };
        }
      });
    }
    return { day, site };
  };
  const HandleDropDownChange = (id, data) => {
    let { day, site } = updateData(id, data);
    updateTable(day.format("YYYY-MM-DD"), site);
  };
  const HandleTableDropDownChange = id => {
    setSelectedTableId(id);
    selectedTable(id);
  };
  /**
   * download function call back
   */
  const onButtonClick = e => {
    e.preventDefault();

    let { day, site } = updateData();
    // Show a toast when user is trying to download todays report
    if (day.isToday()) {
      toast({
        title: "Possible Incomplete Report",
        description:
          "Note: Report could be incomplete as the day is not yet over.",
        status: "info",
        duration: 5000,
        isClosable: true
      });
    }
    onDownloadClick(day.format("YYYY-MM-DD"), site);
  };
  // const width = useBreakpointValue({
  //   base: "85vw",
  //   sm: "80vw",
  //   md: "75vw",
  //   lg: "65vw",
  // });

  return (
    <Flex direction="column">
      {/* <Center> */}
      <Box width="185px" mr="20px" my="10px">
        <Badge variant="subtle" fontSize="16px" colorScheme={colorScheme}>
          {reportName}
        </Badge>
      </Box>
      {/* </Center> */}
      <Flex width={"100%"} wrap="wrap" justifyContent="space-between" my="10px">
        {isMultipleTables && (
          <Select
            variant="filled"
            placeholder={tables?.length ? "Select table" : "No tables found"}
            value={selectedTableId}
            onChange={e => {
              HandleTableDropDownChange(e?.target?.value);
            }}
            size="sm"
            width="150px"
            borderRadius="5px"
            mr="20px"
            my="10px"
          >
            {tables?.length
              ? tables?.map(table => (
                  <option value={table.Id} key={table.Id}>
                    {table?.name}
                  </option>
                ))
              : null}
          </Select>
        )}
        <Select
          variant="filled"
          placeholder={sites?.length ? "Select site" : "No sites found"}
          value={selectedSite}
          onChange={e => {
            HandleDropDownChange("site", e?.target?.value);
          }}
          size="sm"
          width="150px"
          borderRadius="5px"
          mr="20px"
          my="10px"
        >
          {sites?.length
            ? sites?.map(site => (
                <option
                  value={
                    selectID === "website_id"
                      ? site.sap_website_id
                      : site.default_store_id
                  }
                  key={
                    selectID === "website_id"
                      ? site.sap_website_id
                      : site.default_store_id
                  }
                >
                  Site:&nbsp;{site?.sap_website_id}
                </option>
              ))
            : null}
        </Select>
        <Box my="10px" mr="20px">
          <DatePicker
            selected={selectedDate}
            maxDate={new Date()}
            onChange={date => {
              HandleDropDownChange("date", date);
            }}
            dateFormat="PP"
            title={"Select Date"}
            customInput={<CustomDatePicker />}
            todayButton="Select today"
            showPopperArrow={false}
            popperPlacement="bottom"
            disabled={isLoading}
          />
        </Box>
        <Button
          size="sm"
          isLoading={isLoading}
          disabled={isLoading || !selectedSite || !sites?.length}
          rightIcon={<DownloadIcon />}
          onClick={onButtonClick}
          my="10px"
        >
          Download
        </Button>
        {isSearchFilter && (
          <Input
            placeholder="search"
            size="sm"
            my="10px"
            mr="20px"
            width="350px"
            onChange={e => searchFilter(e.target.value)}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ReportHeader;
