import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Table, TableCaption, Flex, useToast } from "@chakra-ui/react";
import dayjs from "dayjs";

import { SalesTableHead } from "../components/TableHeads";
import ReportHeader from "../components/ReportHeader";
import SalesTable from "../containers/SalesTable";

import { exportToExcel } from "../utils/exportToExcel";

import { getSalesReport, getSites } from "../api";
/**
 * Sales report
 */
function SalesReport(props) {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const selectID = params.get("name");
  const defaultSiteID = params.get("id");

  const [emptyLoading, setemptyLoading] = useState(false);
  const [report, setreport] = useState([]);
  const [filterReport, setfilterReport] = useState([]);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSales = async (
    date = dayjs(new Date()).format("YYYY-MM-DD"),
    site = defaultSiteID
  ) => {
    setemptyLoading(true);
    const response = await getSalesReport(date, site);
    setemptyLoading(false);
    setreport(response);
    setfilterReport(response);
  };

  useEffect(() => {
    getSales();
  }, []);

  const onDownloadClick = async (date, site) => {
    try {
      exportToExcel("sales", report, `SALES-${date}-${site}`, "sales");
    } catch (e) {
      console.log(e);
      errorToast(e);
    } finally {
    }
  };

  const searchFilter = (value) => {
    setfilterReport(
      report.filter((item) => {
        return item["Order ID"]
          .toLowerCase()
          .includes(value.toLowerCase().trim());
      })
    );
  };

  const errorToast = (error) =>
    toast({
      title: "Failed to generate report",
      description: error?.data?.message ?? "Something went wrong",
      status: "error",
      duration: 2500,
      isClosable: true,
    });
  return (
    <Flex direction="column">
      <ReportHeader
        reportName={"Sales Report"}
        onDownloadClick={onDownloadClick}
        isLoading={emptyLoading}
        colorScheme="twitter"
        sites={sites}
        defaultSiteID={defaultSiteID}
        updateTable={getSales}
        selectID={selectID}
        searchFilter={searchFilter}
      />
      <Flex overflowX="auto">
        <Table variant="simple" size="sm">
          <TableCaption placement={"top"}>
            {/* Sales Report */}
            {/* {showTableCaption ? tableCaption : ""} */}
          </TableCaption>
          <SalesTableHead />
          <SalesTable report={filterReport ?? []} emptyLoading={emptyLoading} />
        </Table>
      </Flex>
    </Flex>
  );
}
export default SalesReport;
