import { Box, Button, Flex, useToast, Badge, Select } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import React, { useState, useEffect, forwardRef } from "react";

import { getProdSalesReport, getProdSites } from "../api";
import { exportToExcel } from "../utils/exportToExcel";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const TotalReport = () => {
  const toast = useToast();
  const [reportLoading, setreportLoading] = useState(false);
  const [sites, setSites] = useState();
  const [selectedSite, setselectedSite] = useState("8042");
  const [selectedMinDate, setselectedMinDate] = useState(new Date());
  const [selectedMaxDate, setselectedMaxDate] = useState(new Date());

  const loadSites = async () => {
    try {
      const sites = await getProdSites();
      if (sites) {
        setSites(Object.values(sites));
      }
    } catch (e) {
      console.log(e);
      setSites([]);
    }
  };
  useEffect(() => {
    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      disabled={reportLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));
  const totalSalesReportClick = async (minDate, maxDate) => {
    try {
      setreportLoading(true);
      var date;
      // var yesterday = new Date();
      // yesterday.setDate(new Date().getDate() - 1);
      var list_of_array = [];
      for (var d = minDate; d <= maxDate; d.setDate(d.getDate() + 1)) {
        date = dayjs(d);
        date = date.format("YYYY-MM-DD");
        let salesSummaryReport = await getProdSalesReport(date, selectedSite);
        salesSummaryReport.pop();
        list_of_array = list_of_array.concat(salesSummaryReport);
      }
      exportToExcel("sales", list_of_array, `SALES-${selectedSite}`);
    } catch (e) {
      console.log(e);
      errorToast(e);
    } finally {
      setreportLoading(false);
    }
  };
  const errorToast = error =>
    toast({
      title: "Failed to generate report",
      description: error?.data?.message ?? "Something went wrong",
      status: "error",
      duration: 2500,
      isClosable: true
    });

  return (
    <Box>
      <Badge fontSize={20}>Sales report</Badge>
      <Flex>
        <Select
          variant="filled"
          placeholder={sites?.length ? "Select site" : "No sites found"}
          defaultValue={selectedSite}
          value={selectedSite}
          onChange={e => {
            setselectedSite(e?.target?.value);
          }}
          size="sm"
          width="150px"
          borderRadius="5px"
          mr="20px"
          my="10px"
        >
          {sites?.length
            ? sites?.map(site => (
                <option value={site.sap_website_id} key={site.sap_website_id}>
                  Site:&nbsp;{site?.sap_website_id}
                </option>
              ))
            : null}
        </Select>
        <Box my="10px" mr="20px">
          <DatePicker
            selected={selectedMinDate}
            maxDate={new Date()}
            onChange={date => {
              setselectedMinDate(date);
            }}
            dateFormat="PP"
            title={"Select Start Date"}
            customInput={<CustomDatePicker />}
            todayButton="Select today"
            showPopperArrow={false}
            popperPlacement="bottom"
            disabled={reportLoading}
          />
        </Box>
        <Box my="10px" mr="20px">
          <DatePicker
            selected={selectedMaxDate}
            maxDate={new Date()}
            onChange={date => {
              setselectedMaxDate(date);
            }}
            dateFormat="PP"
            title={"Select End Date"}
            customInput={<CustomDatePicker />}
            todayButton="Select today"
            showPopperArrow={false}
            popperPlacement="bottom"
            disabled={reportLoading}
          />
        </Box>
        <Box my="10px" mr="20px">
          <Button
            isLoading={reportLoading}
            disabled={reportLoading}
            onClick={() =>
              totalSalesReportClick(selectedMinDate, selectedMaxDate)
            }
          >
            Download
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default TotalReport;
